<template>
  <ui-modal ref="modal" :callback="cb" :size="'lg'" :can-confirm="validRows">
    <template #title>
      {{ $t('share_link.edit_weights.title') }}
    </template>
    <template #default>
      {{ $t('share_link.edit_weights.text') }}
      <br/>
      <br/>
      <form id="edit-weights-modal-form">
        <table class="table table-bordered table-striped" v-if="!$isMobile">
          <thead>
          <tr>
            <th>{{ $t('form.table.description') }}<span :title="$t('general.required')" class="text-danger">*</span>
            </th>
            <th style="width: 200px">{{ $t('form.table.amount') }}<span :title="$t('general.required')"
                                                                        class="text-danger">*</span></th>
            <th style="width: 200px">{{ $t('form.table.EURAL-code') }}<span :title="$t('general.required')"
                                                                            class="text-danger">*</span></th>
            <th style="width: 60px">{{ $t('form.table.dangerous') }}<span :title="$t('general.required')"
                                                                          class="text-danger">*</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in wasteIdentifications" :key="index">
            <td>
              <input class="form-control" v-model="row.description" required/>
            </td>
            <td>
              <div class="container">
                <div class="row">
                  <number-input style="width: 66.666% !important" v-model="row.amountAsBigDecimal" :required="true" min="0"/>
                  <input class="form-control" style="width: 33.333% !important" readonly
                         :placeholder="$t('form.table.tonne')"/>
                </div>
              </div>
            </td>
            <td data-cy="euralCodes">
              <input class="form-control" :value="row.euralCode ? row.euralCode.substring(6).replaceAll('_', ' ') : ''"
                     required readonly/>
            </td>
            <td class="align-middle">
              <div class="form-check d-flex justify-content-center">
                <input class="form-check-input" type="checkbox" disabled v-model="row.dangerous">
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered table-striped" v-else>
          <thead>
          <tr>
            <th>{{ $t('form.table.description') }}<span :title="$t('general.required')" class="text-danger">*</span>
            </th>
            <th style="width: 200px">{{ $t('form.table.amount') }}<span :title="$t('general.required')"
                                                                        class="text-danger">*</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in wasteIdentifications" :key="index">
            <td>
              <input class="form-control" v-model="row.description" required/>
            </td>
            <td>
              <div class="container">
                <div class="row">
                  <number-input v-model="row.amountAsBigDecimal" :required="true" min="0" @keyup.enter="modal.confirm"/>
                  <input class="col form-control" readonly :placeholder="$t('form.table.tonne')"/>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import NumberInput from '@/components/NumberInput.vue'
import {storeToRefs} from 'pinia'
import {useEditWeightsStore} from '@/stores/modals/EditWeightsStore'

export default {
  name:       'FormEditWeightsModal',
  components: {
    NumberInput,
    UiModal,
  },
  props:      {
    form:     {
      type: Object,
    },
    callback: {
      type: Function,
    },
  },
  setup:      (props) => {
    const modal            = ref('modal')
    const editWeightsStore = useEditWeightsStore()

    const cb = async () => {
      const formElement = $('#edit-weights-modal-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        return false
      }

      if (props.callback) {
        let res = props.callback()
        if (res) {
          modal.value.close()
        }
      } else {
        await editWeightsStore.saveEditWeights()
      }
    }

    const {wasteIdentifications} = storeToRefs(editWeightsStore)
    const validRows              = () => {
      let result = true
      wasteIdentifications.value.forEach((row) => {
        if (!row.amount) {
          result = false
        }
      })
      return result
    }

    return {
      modal,
      editWeightsStore,
      ...storeToRefs(editWeightsStore),
      cb,
      validRows,
    }
  },
}
</script>
