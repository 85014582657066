import DocumentType from '@/types/documentType'
import FormCheckState from '@/types/formCheckState'
import IdentificationType from '@/types/identificationType'
import MeansOfTransport from '@/types/meansOfTransport'
import DateValidator from '@/util/dateValidator'
import identificationService from '@/services/identificationService'

const oneOfRequiredTypes = [IdentificationType.VAT, IdentificationType.COMPANY, IdentificationType.BRANCH_NUMBER, IdentificationType.INDIVIDUAL, IdentificationType.MMSI, IdentificationType.IMO]

const checkRequiredIdentificationTypes = (form) => {
  function check(identifications) {
    let found = false
    const identificationTypes = identifications.map((identification) => { return identification.identificationType })
    for (let i = 0; i < identificationTypes.length; i++) {
      if (oneOfRequiredTypes.indexOf(identificationTypes[i]) > -1) {
        found = true
      }
    }
    return found
  }

  const producer = check(form.producerIdentification.identifications)
  const processor = check(form.processorIdentification.identifications)
  let organiser = true
  if (form.organiserIdentification.name) {
    organiser = check(form.organiserIdentification.identifications)
  }
  let transporters = form.transporterIdentifications.length > 0
  for (let i = 0; i < form.transporterIdentifications.length; i++) {
    if (!check(form.transporterIdentifications[i].identifications)) {
      transporters = false
      break
    }
  }
  return producer && processor && organiser && transporters
}

const checkAlways = () => {
  const formElement = $('#waste-identification-form')[0]
  if (!formElement) {
    return 'toast.form_missing'
  }
  if (!formElement.checkValidity()) {
    // something in the form is not valid
    return 'toast.form_incomplete'
  }
}

const checkToSign = (form) => {
  const localOffset = new Date().getTimezoneOffset() / -60 // offset to UTC in hours
  let date          = new Date(form.transportDate)
  date.setHours(date.getHours() + localOffset)
  date        = date.setUTCHours(0, 0, 0, 0)
  const today = new Date().setUTCHours(0, 0, 0, 0)
  // if date is in past
  if (date < today) {
    return 'toast.date_in_past'
  }

  const vatElements = $('.identification-to-validate')
  for (let i = 0; i < vatElements.length; i++) {
    const el = vatElements[i]
    if (!el?.checkValidity()) {
      return 'toast.invalid_identification'
    }
  }

  const wasteAmounts = $('.waste-amount')
  for (let i = 0; i < wasteAmounts.length; i++) {
    const el = wasteAmounts[i]
    if (!el?.checkValidity()) {
      return 'toast.invalid_amount'
    }
    if (Number(el.value) === 0) {
      el.classList.add('invalid-field')
      return 'toast.invalid_amount_zero'
    }
  }

  if (!checkRequiredIdentificationTypes(form)) {
    return 'toast.missing_required_identification_type'
  }
}

const isVlaams = (address) => {
  return address?.country === 'BE' &&
    (
      (address?.zip >= 1500 && address?.zip < 4000) ||
      (address?.zip >= 8000)
    )
}

const checkMatis = (form) => {
  // if no IHM then no upload needed
  if (!form.organiserIdentification.name) {
    return {state: FormCheckState.NO_UPLOAD, fields: []}
  }

  // if producer shipment address (pickup location) not from flanders then no upload needed
  const address = form?.producerIdentification?.shipmentAddress?.zip
                  ? form.producerIdentification.shipmentAddress
                  : form.producerIdentification.address
  if (!isVlaams(address)) {
    return {state: FormCheckState.NO_UPLOAD, fields: []}
  }

  const fields = []

  if (form?.producerIdentification?.identifications.filter((identification) => {
    return [
      IdentificationType.INDIVIDUAL,
      IdentificationType.COMPANY,
      IdentificationType.VAT,
      IdentificationType.BRANCH_NUMBER,
    ].indexOf(identification.identificationType) > -1
  }).length === 0) {
    fields.push('producer_identification')
  }

  if (!form?.producerIdentification?.locationType) {
    fields.push('producer_location_type')
  }

  if (form?.processorIdentification?.identifications.filter((identification) => {
    return [
      IdentificationType.INDIVIDUAL,
      IdentificationType.COMPANY,
      IdentificationType.VAT,
      IdentificationType.BRANCH_NUMBER,
    ].indexOf(identification.identificationType) > -1
  }).length === 0) {
    fields.push('processor_identification')
  }

  if (!form?.processorIdentification?.locationType) {
    fields.push('processor_location_type')
  }

  // flemish processor can not be location type 'werf'
  const processorAddress =
          form?.processorIdentification?.shipmentAddress?.id
          ? form.processorIdentification.shipmentAddress
          : form.processorIdentification.address
  if (form?.processorIdentification?.locationType === 'WERF' && processorAddress && isVlaams(processorAddress)) {
    fields.push('processor_location_type_werf_vlaanderen')
  }

  const transporterCount = form?.transporterIdentifications?.length
  let motCount           = 0
  form?.transporterIdentifications.forEach((t) => {
    if (t.meansOfTransport) {
      motCount++
    }
  })
  if (!(transporterCount > 0 && transporterCount === motCount)) {
    fields.push('transporter_means_of_transport')
  }

  if (fields.length > 0) {
    return {
      state: FormCheckState.BAD_FIELDS, fields: fields,
    }
  }
  return {
    state:  FormCheckState.OK,
    fields: [],
  }
}

const checkMaxWeight = (form) => {
  let check = false
  for (let j = 0; j < form.transporterIdentifications.length; j++) {
    if (!form.transporterIdentifications[j].meansOfTransport || form.transporterIdentifications[j].meansOfTransport === MeansOfTransport.ROAD) {
      check = true
      break
    }
  }
  if (check) {
    for (let i = 0; i < form.wasteIdentifications.length; i++) {
      if (form.wasteIdentifications[i].amountAsBigDecimal > 50) {
        return 'warning.heavy_load'
      }
    }
  }

  return null
}

const WasteIdentificationModel = {
  empty:                {
    id:                         null,
    externalId:                 null,
    type:                       DocumentType.WASTE_IDENTIFICATION,
    state:                      'DRAFT',
    notes:                      '',
    transportDate:              null,
    producerIdentification:     {
      language: 'NL', address: null, shipmentAddress: null, identifications: [
        {
          value: null, identificationType: null,
        },
      ],
    },
    organiserIdentification:    {
      language: 'NL', address: null, identifications: [
        {
          value: null, identificationType: null,
        },
      ],
    },
    transporterIdentifications: [
      {
        language: 'NL', address: null, identifications: [
          {
            value: null, identificationType: null,
          },
        ],
      },
    ],
    processorIdentification:    {
      language: 'NL', address: null, shipmentAddress: null, identifications: [
        {
          value: null, identificationType: null,
        },
      ],
    },
    wasteIdentifications:       [
      {
        'description': '', 'amount': '', 'euralCode': null, 'removalOperation': null, 'processingTechnique': null,
      },
    ],
    organiserSignature:         null,
    processorSignature:         null,
    startPoint:                 null,
    endPoint:                   null,
  },
  modelCheck:           (model, options = {}) => {
    const date         = (model.transportDate && (options?.allowPast || !DateValidator.dateIsInPast(model.transportDate)))
    const producer     = WasteIdentificationModel.modelProducer(model)
    const processor    = WasteIdentificationModel.modelProcessor(model)
    const organiser    = WasteIdentificationModel.modelOrganiser(model)
    const transporters = WasteIdentificationModel.modelTransporters(model)
    const wastes       = WasteIdentificationModel.modelCheckWastes(model)
    return !!(date &&
      producer &&
      processor &&
      organiser &&
      transporters &&
      wastes
    )
  },
  modelIdentifications: (model, allowZero = false) => {
    if (!model || !model.identifications || model.identifications.length === 0) {
      return allowZero
    }
    //let found = allowZero
    for (let i = 0; i < model.identifications.length; i++) {
      const identification = model.identifications[i]

      if (allowZero && identification.value === null && identification.identificationType === null) {
        continue
      }

      if (!identification.value || !identification.identificationType) {
        return false
      }

      /*if (oneOfRequiredTypes.indexOf(identification.identificationType) > -1) {
        found = true
      }*/
    }

    return identificationService.validateIdentifications(model)
  },
  modelCheckAddress:    (address) => {
    return !!(address &&
      address.street &&
      address.number &&
      address.zip &&
      address.city &&
      address.country)
  },
  modelProducer:        (model) => {
    return !!(model.producerIdentification?.name &&
      WasteIdentificationModel.modelCheckAddress(model.producerIdentification?.address) &&
      WasteIdentificationModel.modelCheckAddress(model.producerIdentification?.shipmentAddress) &&
      WasteIdentificationModel.modelProducerZip(model) &&
      model.producerIdentification?.email &&
      model.producerIdentification?.language &&
      WasteIdentificationModel.modelIdentifications(model.producerIdentification)
    )
  },
  modelProducerZip:     (model) => {
    const address = model?.producerIdentification?.shipmentAddress?.zip
                    ? model.producerIdentification.shipmentAddress
                    : model.producerIdentification.address
    return address.zip >= 1000 && address.zip <= 9999
  },
  modelProcessor:       (model) => {
    return !!(model.processorIdentification?.name &&
      WasteIdentificationModel.modelCheckAddress(model.processorIdentification?.address) &&
      model.processorIdentification?.email &&
      model.processorIdentification?.language &&
      WasteIdentificationModel.modelIdentifications(model.processorIdentification)
    )
  },
  modelOrganiser:       (model) => {
    return WasteIdentificationModel.modelIdentifications(model.organiserIdentification, true)
  },
  modelTransporters:    (model) => {
    if (model.transporterIdentifications.length === 0) {
      return false
    }
    for (let i = 0; i < model.transporterIdentifications.length; i++) {
      if (!WasteIdentificationModel.modelTransporter(model.transporterIdentifications[i])) {
        return false
      }
    }
    return true
  },
  modelTransporter:     (transporter) => {
    return !!(transporter?.name &&
      WasteIdentificationModel.modelCheckAddress(transporter?.address) &&
      transporter?.language &&
      WasteIdentificationModel.modelIdentifications(transporter)
    )
  },
  modelCheckWastes:     (model) => {
    if (model.wasteIdentifications.length === 0) {
      return false
    }
    for (let i = 0; i < model.wasteIdentifications.length; i++) {
      if (!WasteIdentificationModel.modelCheckWaste(model.wasteIdentifications[i])) {
        return false
      }
    }
    return true
  },
  modelCheckWaste:      (waste) => {
    return !!(waste.description &&
      waste.amount &&
      Number(waste.amount.replace(',', '.')) > 0 &&
      waste.euralCode &&
      waste.removalOperation &&
      waste.processingTechnique &&
      (!waste.dangerous || (
        waste.physical &&
        waste.chemical &&
        waste.packagingType &&
        (waste.amountOfPackages || waste.amountOfPackages === 0)
      ))
    )
  },
  formCheck:            (form, allChecks = false) => {
    let r = checkAlways(form)
    if (r) {
      return r
    }
    if (allChecks) {
      r = checkToSign(form)
      if (r) {
        return r
      }
    }
  },
  matisCheck:           (form) => {
    return checkMatis(form)
  },
  warningChecks:        (form) => {
    let warning = checkMaxWeight(form)
    if (warning) {
      return warning
    }
    return null
  },
  oneOfRequiredTypes: oneOfRequiredTypes,
}

export default WasteIdentificationModel
